import { Fragment, lazy, Suspense, useMemo } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import getStore from 'redux/store';
import { theme } from 'styles/theme';
import PublicRoutes from 'routes/public-routes';
import PrivateRoute from 'routes/private-route';
import BaseLayOut from 'layout';
import { ConfigProvider, Spin } from 'antd';
import { customRoutes } from 'routes';

const EntitiesPage = lazy(() => import('pages/entities'));
const LoginPage = lazy(() => import('pages/login'));

function App() {
  const store = useMemo(() => {
    return getStore();
  }, []);

  return (
    <StoreProvider store={store}>
      <ConfigProvider theme={theme}>
        <Routes>
          <Route path="login" element={<PublicRoutes />}>
            <Route path="/login" element={<LoginPage />} />
          </Route>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<BaseLayOut />}>
              <Route
                path="/"
                element={
                  <Suspense fallback={<Spin tip="Loading..." />}>
                    <EntitiesPage />
                  </Suspense>
                }
              />
              {customRoutes?.map(item => (
                <Fragment key={item.path}>
                  <Route path={item.path} element={item.component} index={item.index} />
                  {item.children &&
                    item.children.map(child => (
                      <Fragment key={child.path}>
                        <Route path={child.path} element={child.component} key={child.path} index={child.index} />
                        {child.children &&
                          child.children.map(routeChild => (
                            <Route
                              path={routeChild.path}
                              element={routeChild.component}
                              key={routeChild.path}
                              index={routeChild.index}
                            />
                          ))}
                      </Fragment>
                    ))}
                </Fragment>
              ))}
            </Route>
          </Route>
        </Routes>
      </ConfigProvider>
    </StoreProvider>
  );
}

export default App;
