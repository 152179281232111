export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: ' calc(100vh - 100px)',
  },
  image: {
    width: '186px',
    height: '186px',
  },
  message: {
    width: '299px',
    marginTop: '33px',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    textAlign: 'center',
  },
};
