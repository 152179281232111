import { creditUrl } from '../utils';

export const getHistoryCollectionsRequest = ({ page, limit, ...params }) => {
  return {
    url: `${creditUrl}admin/collections?page=${page}&limit=${limit}`,
    method: 'GET',
    params,
  };
};

export const getCurrentCollectionsRequest = ({ page, limit, ...params }) => {
  return {
    url: `${creditUrl}admin/retailers/due-amounts?page=${page}&limit=${limit}`,
    method: 'GET',
    params,
  };
};

export const postRetailerSettlementRequest = ({ body }) => {
  return {
    url: `${creditUrl}admin/collections`,
    method: 'POST',
    body,
  };
};
