/* eslint-disable func-style */
import { takeLatest, put, call } from 'redux-saga/effects';
import { execute } from 'api';

import {
  loadCollectorsList,
  loadCollectorsListSuccess,
  loadCollectorsListFailed,
  loadAssignedRetailersList,
  loadAssignedRetailersListSuccess,
  loadAssignedRetailersListFailed,
  loadingCollectorData,
  loadCollectorDataSuccess,
  loadCollectorDataFailed,
  createCollector,
  createCollectorSuccess,
  createCollectorFailed,
  loadingEditCollector,
  loadingEditCollectorSuccess,
  loadingEditCollectorFailed,
  loadingUploadRetailers,
  loadingUploadRetailersSuccess,
  loadingUploadRetailersFailed,
  loadingDownloadRetailers,
  loadingDownloadRetailersSuccess,
  loadingDownloadRetailersFailed,
  loadHistoryCollectorList,
  loadHistoryCollectorListSuccess,
  loadHistoryCollectorListFailed,
  loadHistoryPaymentList,
  loadHistoryPaymentListSuccess,
  loadHistoryPaymentListFailed,
  loadingDownloadAssignedRetailers,
  loadingDownloadAssignedRetailersSuccess,
  loadingDownloadAssignedRetailersFailed,
} from './slice';
import { showAlert } from 'redux/slices/uiSlice';

import {
  getCollectorsRequest,
  getAssignedRetailersRequest,
  getCollectorRequest,
  getEditCollectorRequest,
  getCreateCollectorRequest,
  getUploadCollectorAssignedRetailersRequest,
  getCollectorsAssignmentTemplate,
  getHistoryCollectorsRequest,
  getHistoryPaymentRequest,
  getDownloadAssignedRetailersTemplate,
} from 'api/requestCreators/collectors';

import { downloadExcelSheet, handleUploadSheetError } from 'utils';
import { showError } from 'redux/utility-sagas';

function* handleLoadCollectorsListRequests(action) {
  const filters = { ...action?.payload };
  try {
    const {
      data: { data },
    } = yield call(execute, getCollectorsRequest(filters));

    yield put(loadCollectorsListSuccess(data));
  } catch (error) {
    yield put(loadCollectorsListFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

function* handleLoadAssignedRetailersListRequests(action) {
  const filters = action?.payload;
  try {
    const {
      data: { data },
    } = yield call(execute, getAssignedRetailersRequest(filters));

    yield put(loadAssignedRetailersListSuccess(data));
  } catch (error) {
    yield put(loadAssignedRetailersListFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

function* handleLoadCollectorDataRequests(action) {
  const { payload: id } = action;
  try {
    const {
      data: {
        data: { collector },
      },
    } = yield call(execute, getCollectorRequest(id));
    yield put(loadCollectorDataSuccess(collector));
  } catch (error) {
    yield put(loadCollectorDataFailed(error.response?.data?.error));
    yield put(
      showAlert({
        message: error.response?.data?.error,
        type: 'error',
      })
    );
  }
}

function* handleEditCollectorRequests(action) {
  const { values, id } = action.payload;
  try {
    const item = {
      ...values,
    };

    const {
      data: { data },
    } = yield call(execute, getEditCollectorRequest(item, id));

    yield put(loadingEditCollectorSuccess(data));
  } catch (error) {
    yield put(loadingEditCollectorFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

function* handleCreateCollectorRequests(action) {
  try {
    const item = {
      ...action.payload,
    };

    const {
      data: { data },
    } = yield call(execute, getCreateCollectorRequest(item));

    yield put(createCollectorSuccess(data));
  } catch (error) {
    yield put(createCollectorFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

function* handleUploadRetailersRequests(action) {
  const { collector_id, retailers } = action.payload;
  try {
    const response = yield call(execute, getUploadCollectorAssignedRetailersRequest(collector_id, { retailers }));
    yield put(loadingUploadRetailersSuccess(response));
    yield put(loadAssignedRetailersList({ page: 1, limit: 10, id: collector_id }));
  } catch (error) {
    let handledResponse = handleUploadSheetError(error);
    if (error.response.status === 500) {
      yield put(
        showAlert({
          message: 'Error in bulk uploading, Please check the upload sheet',
          type: 'error',
        })
      );
    } else {
      downloadExcelSheet(handledResponse, 'uploadErrorSheet.xlsx');
      yield put(
        showAlert({
          message: 'Error in bulk uploading, Please check the downloaded error sheet',
          type: 'error',
        })
      );
      yield put(loadAssignedRetailersList({ page: 1, limit: 10, id: collector_id }));
    }
    yield put(loadingUploadRetailersFailed({}));
  }
}

function* handleDownloadRetailersRequests() {
  try {
    const response = yield call(execute, getCollectorsAssignmentTemplate());
    downloadExcelSheet(response, 'CollectorsAssignmentTemplate.xlsx');
    yield put(loadingDownloadRetailersSuccess());
  } catch (error) {
    yield put(loadingDownloadRetailersFailed(error.response?.data));
    yield showError(error, 'Error in download, Please try again');
  }
}

function* handleLoadHistoryCollectorsListRequests(action) {
  const filters = { ...action?.payload };
  try {
    const {
      data: { data },
    } = yield call(execute, getHistoryCollectorsRequest(filters));

    yield put(loadHistoryCollectorListSuccess(data));
  } catch (error) {
    yield put(loadHistoryCollectorListFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

function* handleLoadHistoryPaymentListRequests(action) {
  const filters = { ...action?.payload };
  try {
    const {
      data: { data },
    } = yield call(execute, getHistoryPaymentRequest(filters));

    yield put(loadHistoryPaymentListSuccess(data));
  } catch (error) {
    yield put(loadHistoryPaymentListFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

function* handleDownloadAssignedRetailersRequests(action) {
  const id = action.payload;
  try {
    const response = yield call(execute, getDownloadAssignedRetailersTemplate(id));
    downloadExcelSheet(response, 'AssignedRetailers.xlsx');
    yield put(loadingDownloadAssignedRetailersSuccess());
  } catch (error) {
    yield put(loadingDownloadAssignedRetailersFailed(error.response?.data));
    yield showError(error, 'Error in download, Please try again');
  }
}

export function* watchCollectorsPageRequests() {
  yield takeLatest(loadCollectorsList.type, handleLoadCollectorsListRequests);
  yield takeLatest(loadAssignedRetailersList.type, handleLoadAssignedRetailersListRequests);
  yield takeLatest(loadingCollectorData.type, handleLoadCollectorDataRequests);
  yield takeLatest(loadingEditCollector.type, handleEditCollectorRequests);
  yield takeLatest(createCollector.type, handleCreateCollectorRequests);
  yield takeLatest(loadingUploadRetailers.type, handleUploadRetailersRequests);
  yield takeLatest(loadingDownloadRetailers.type, handleDownloadRetailersRequests);
  yield takeLatest(loadHistoryCollectorList.type, handleLoadHistoryCollectorsListRequests);
  yield takeLatest(loadHistoryPaymentList.type, handleLoadHistoryPaymentListRequests);
  yield takeLatest(loadingDownloadAssignedRetailers.type, handleDownloadAssignedRetailersRequests);
}
