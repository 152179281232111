/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { getFormatDate } from 'utils';
import { WALLET_STATUS } from 'pages/collectors/types';

const initialState = {
  isLoading: true,
  createStatus: 'idle',
  error: null,
  data: null,
  actionStatus: 'idle',
  entityItem: null,
  totalCount: 10,
  uploadLoading: false,
  downloadLoading: false,
  downloadStatus: false,
  uploadSuccess: false,
};

const EntitiesListSlice = createSlice({
  name: 'entitiesList',
  initialState,
  reducers: {
    loadEntities: state => state,
    loadEntitiesSuccess: (state, action) => {
      state.data = action.payload?.result;
      state.totalCount = action.payload?.totalCount || 10;
      state.isLoading = false;
      state.downloadStatus = false;
      state.uploadSuccess = false;
    },
    loadEntitiesFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    createEntity: (state, action) => state,
    createEntitySuccess: (state, action) => {
      state.createStatus = 'success';
      state.isLoading = false;
      state.error = null;
      state.downloadStatus = false;
    },
    createEntityFailed: (state, action) => {
      state.createStatus = 'error';
      state.error = action.payload;
      state.isLoading = false;
      state.downloadStatus = false;
    },

    setItemEntity: (state, action) => {
      state.entityItem = action.payload;
      state.isLoading = false;
      state.downloadStatus = false;
    },

    loadingEntityData: state => {
      state.isLoading = true;
      return state;
    },
    loadEntityDataSuccess: (state, action) => {
      const retailerObject = { ...action.payload?.retailer };
      const convertDueDate = retailerObject?.wallet?.nearestDueDate
        ? getFormatDate({
            date: new Date(+retailerObject?.wallet?.nearestDueDate),
            locale: 'en-US',
            formatType: 'dd-mm-yyyy',
          })
        : '';
      const retrieveEntityItem = {
        userId: retailerObject?.id,
        entityId: retailerObject?.store?.id,
        name: retailerObject.name,
        phone: retailerObject?.phone,
        status: retailerObject?.store?.status === 'active' ? true : false,
        storeName: retailerObject?.store?.name,
        sapId: retailerObject?.store?.sapId,
        secondPhone: retailerObject?.store?.secondPhone,
        address: retailerObject?.store?.address,
        government: retailerObject?.store?.route?.area?.id,
        salesOfficeId: retailerObject?.store?.route?.salesOffice?.id,
        routeId: retailerObject?.store?.route?.id,
        nationalId: retailerObject?.store?.nationalId,
        wallet: {
          ...retailerObject.wallet,
          walletStatus: WALLET_STATUS[retailerObject?.wallet?.walletStatus] ?? '',
          nearestDueDate: convertDueDate,
          limit: 0,
        },
      };

      state.entityItem = retrieveEntityItem;
      state.isLoading = false;
      state.downloadStatus = false;
    },

    loadEntityDataFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.downloadStatus = false;
    },
    resetEntityState: state => {
      state.entityItem = initialState.entityItem;
    },
    restCreateStatus: state => {
      state.createStatus = 'idle';
    },

    loadingApproveEntity: state => state,
    loadingApproveEntitySuccess: (state, action) => {
      state.createStatus = 'success';
      state.isLoading = false;
      state.error = null;
    },
    loadingApproveEntityFailed: (state, action) => {
      state.createStatus = 'error';
      state.error = action.payload;
      state.isLoading = false;
    },

    loadingEditEntity: state => state,
    loadingEditEntitySuccess: (state, action) => {
      state.createStatus = 'success';
      state.isLoading = false;
      state.error = null;
      state.downloadStatus = false;
    },
    loadingEditEntityFailed: (state, action) => {
      state.createStatus = 'error';
      state.error = action.payload;
      state.isLoading = false;
      state.downloadStatus = false;
    },

    loadingUploadEntities: state => {
      state.uploadLoading = true;
      state.downloadStatus = false;
      return state;
    },
    loadingUploadEntitiesSuccess: (state, action) => {
      state.uploadLoading = false;
      state.error = null;
      state.downloadStatus = false;
      state.uploadSuccess = true;
    },
    loadingUploadEntitiesFailed: (state, action) => {
      state.error = action.payload;
      state.uploadLoading = false;
      state.downloadStatus = false;
    },

    loadingDownloadEntities: state => {
      state.downloadLoading = true;
      return state;
    },
    loadingDownloadEntitiesSuccess: (state, action) => {
      state.downloadLoading = false;
      state.downloadStatus = true;
      state.error = null;
    },
    loadingDownloadEntitiesFailed: (state, action) => {
      state.error = action.payload;
      state.downloadLoading = false;
    },

    resetState: () => {
      return { ...initialState };
    },
  },
});

const { actions, reducer } = EntitiesListSlice;

export const {
  loadEntities,
  loadEntitiesSuccess,
  loadEntitiesFailed,
  createEntity,
  createEntitySuccess,
  createEntityFailed,

  setItemEntity,
  loadingEntityData,
  loadEntityDataSuccess,
  loadEntityDataFailed,

  resetEntityState,
  restCreateStatus,

  loadingEditEntity,
  loadingEditEntitySuccess,
  loadingEditEntityFailed,

  loadingApproveEntity,
  loadingApproveEntitySuccess,
  loadingApproveEntityFailed,

  loadingUploadEntities,
  loadingUploadEntitiesSuccess,
  loadingUploadEntitiesFailed,

  loadingDownloadEntities,
  loadingDownloadEntitiesSuccess,
  loadingDownloadEntitiesFailed,
} = actions;

export default reducer;
