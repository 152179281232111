import { creditUrl } from '../utils';
import qs from 'qs';

export const getCollectorsRequest = ({ page, limit, ...filters }) => {
  return {
    url: `${creditUrl}admin/collectors`,
    method: 'GET',
    params: {
      filter: { ...filters?.filter },
      limit,
      page,
    },
    paramsSerializer: function (params) {
      return qs.stringify(params, {
        skipNulls: true,
      });
    },
  };
};

export const getAssignedRetailersRequest = ({ page, limit, id }) => {
  return {
    url: `${creditUrl}admin/collectors/${id}/retailers?page=${page}&limit=${limit}`,
    method: 'GET',
  };
};

export const getCollectorRequest = id => {
  return {
    url: `${creditUrl}admin/collectors/${id}`,
    method: 'GET',
  };
};

export const getEditCollectorRequest = (data, id) => {
  return {
    url: `${creditUrl}admin/collectors/${id}`,
    method: 'PUT',
    body: data,
  };
};

export const getCreateCollectorRequest = data => {
  return {
    url: `${creditUrl}admin/collectors`,
    method: 'POST',
    body: data,
  };
};

export const getUploadCollectorAssignedRetailersRequest = (collector_id, data) => {
  return {
    url: `${creditUrl}admin/collectors/${collector_id}/assignments`,
    method: 'POST',
    body: data,
    responseType: 'arraybuffer',
  };
};

export const getCollectorsAssignmentTemplate = () => {
  return {
    url: `${creditUrl}admin/collectors/assignment/template`,
    method: 'GET',
    responseType: 'arraybuffer',
  };
};

export const getHistoryCollectorsRequest = ({ page, limit, id }) => {
  return {
    url: `${creditUrl}admin/collectors/${id}/collections?page=${page}&limit=${limit}`,
    method: 'GET',
  };
};

export const getHistoryPaymentRequest = ({ page, limit, id }) => {
  return {
    url: `${creditUrl}admin/collectors/collections/${id}?page=${page}&limit=${limit}`,
    method: 'GET',
  };
};

export const getDownloadAssignedRetailersTemplate = id => {
  return {
    url: `${creditUrl}admin/collectors/assignment/template?collectorId=${id}`,
    method: 'GET',
    responseType: 'arraybuffer',
  };
};
