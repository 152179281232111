/* eslint-disable func-style */
import { takeLatest, put, call } from 'redux-saga/effects';
import { execute } from 'api';

import { loadRoutes, loadRoutesSuccess, loadRoutesFailed } from './slice';
import { getRoutesRequest } from 'api/requestCreators/routes';
import { showAlert } from 'redux/slices/uiSlice';

function* handleLoadRouteListRequests(action) {
  try {
    const filters = { ...action?.payload };

    const {
      data: { data },
    } = yield call(execute, getRoutesRequest(filters));

    yield put(loadRoutesSuccess(data));
  } catch (error) {
    yield put(loadRoutesFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

export function* watchRouteListRequests() {
  yield takeLatest(loadRoutes.type, handleLoadRouteListRequests);
}
