import { createSlice } from '@reduxjs/toolkit';
/* eslint-disable no-unused-vars */
const initialState = {
  isLoading: true,
  createStatus: 'idle',
  error: null,
  data: null,
  actionStatus: 'idle',
  collectorItem: null,
  totalCount: 10,
  assignedRetailersList: null,
  uploadLoading: false,
  downloadLoading: false,
  downloadStatus: false,
  uploadSuccess: false,
  historyList: null,
  historyTotalCount: 10,
  paymentList: null,
  paymentListTotalCount: 10,
  downloadAssignedStatus: false,
  downloadAssignedLoading: false,
};

const collectorsSlice = createSlice({
  name: 'collectors',
  initialState,
  reducers: {
    loadCollectorsList: state => state,
    loadCollectorsListSuccess: (state, action) => {
      state.data = action?.payload?.collectors;
      state.totalCount = action?.payload?.totalCount;
      state.isLoading = false;
      state.downloadStatus = false;
    },
    loadCollectorsListFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.downloadStatus = false;
    },
    loadAssignedRetailersList: state => state,
    loadAssignedRetailersListSuccess: (state, action) => {
      state.assignedRetailersList = action?.payload?.retailers;
      state.totalCount = action?.payload?.totalCount;
      state.isLoading = false;
      state.downloadStatus = false;
      state.uploadSuccess = false;
    },
    loadAssignedRetailersListFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.downloadStatus = false;
    },
    setItemCollector: (state, action) => {
      state.collectorItem = action.payload;
      state.isLoading = false;
    },

    loadingCollectorData: state => state,
    loadCollectorDataSuccess: (state, action) => {
      state.collectorItem = action.payload;
      state.isLoading = false;
      state.downloadStatus = false;
    },
    loadCollectorDataFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.downloadStatus = false;
    },

    loadingEditCollector: state => state,
    loadingEditCollectorSuccess: state => {
      state.createStatus = 'success';
      state.isLoading = false;
      state.error = null;
      state.downloadStatus = false;
    },
    loadingEditCollectorFailed: (state, action) => {
      state.createStatus = 'error';
      state.error = action.payload;
      state.isLoading = false;
      state.downloadStatus = false;
    },

    createCollector: state => state,
    createCollectorSuccess: state => {
      state.createStatus = 'success';
      state.isLoading = false;
      state.error = null;
      state.downloadStatus = false;
    },
    createCollectorFailed: (state, action) => {
      state.createStatus = 'error';
      state.error = action.payload;
      state.isLoading = false;
      state.downloadStatus = false;
    },

    restCreateStatus: state => {
      state.createStatus = 'idle';
    },

    loadingUploadRetailers: state => {
      state.uploadLoading = true;
      state.downloadStatus = false;
      return state;
    },
    loadingUploadRetailersSuccess: (state, action) => {
      state.uploadLoading = false;
      state.error = null;
      state.downloadStatus = false;
      state.uploadSuccess = true;
    },
    loadingUploadRetailersFailed: (state, action) => {
      state.error = action.payload;
      state.uploadLoading = false;
      state.downloadStatus = false;
    },

    loadingDownloadRetailers: state => {
      state.downloadLoading = true;
      state.downloadStatus = false;
      return state;
    },
    loadingDownloadRetailersSuccess: (state, action) => {
      state.downloadLoading = false;
      state.error = null;
      state.downloadStatus = true;
    },
    loadingDownloadRetailersFailed: (state, action) => {
      state.error = action.payload;
      state.downloadLoading = false;
    },

    loadHistoryCollectorList: state => state,
    loadHistoryCollectorListSuccess: (state, action) => {
      state.historyList = action?.payload?.collections;
      state.historyTotalCount = action?.payload?.totalCount;
      state.isLoading = false;
    },
    loadHistoryCollectorListFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    loadHistoryPaymentList: state => state,
    loadHistoryPaymentListSuccess: (state, action) => {
      state.paymentList = action?.payload?.result;
      state.paymentListTotalCount = action?.payload?.totalCount;
      state.isLoading = false;
    },
    loadHistoryPaymentListFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    loadingDownloadAssignedRetailers: state => {
      state.downloadAssignedLoading = true;
      state.downloadAssignedStatus = false;
      return state;
    },
    loadingDownloadAssignedRetailersSuccess: (state, action) => {
      state.downloadAssignedLoading = false;
      state.error = null;
      state.downloadAssignedStatus = true;
    },
    loadingDownloadAssignedRetailersFailed: (state, action) => {
      state.error = action.payload;
      state.downloadAssignedStatus = false;
      state.downloadAssignedLoading = false;
    },
    restDownloadAssignedRetailers: (state, action) => {
      state.error = null;
      state.downloadAssignedStatus = false;
      state.downloadAssignedLoading = false;
    },
  },
});

export const {
  loadCollectorsList,
  loadCollectorsListSuccess,
  loadCollectorsListFailed,

  loadAssignedRetailersList,
  loadAssignedRetailersListSuccess,
  loadAssignedRetailersListFailed,
  setItemCollector,

  loadingCollectorData,
  loadCollectorDataSuccess,
  loadCollectorDataFailed,

  createCollector,
  createCollectorSuccess,
  createCollectorFailed,

  loadingEditCollector,
  loadingEditCollectorSuccess,
  loadingEditCollectorFailed,
  restCreateStatus,

  loadingUploadRetailers,
  loadingUploadRetailersSuccess,
  loadingUploadRetailersFailed,

  loadingDownloadRetailers,
  loadingDownloadRetailersSuccess,
  loadingDownloadRetailersFailed,

  loadHistoryCollectorList,
  loadHistoryCollectorListSuccess,
  loadHistoryCollectorListFailed,

  loadHistoryPaymentList,
  loadHistoryPaymentListSuccess,
  loadHistoryPaymentListFailed,

  loadingDownloadAssignedRetailers,
  loadingDownloadAssignedRetailersSuccess,
  loadingDownloadAssignedRetailersFailed,
  restDownloadAssignedRetailers,
} = collectorsSlice.actions;
export default collectorsSlice.reducer;
