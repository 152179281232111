import { Navigate, Outlet } from 'react-router-dom';

const useAuth = () => {
  let user;

  const getUser = localStorage.getItem('user');
  const token = localStorage.getItem('token');

  if (getUser && token) {
    user = JSON.parse(getUser);
  }

  if (user) {
    return {
      auth: true,
      role: user?.role,
    };
  }
  return {
    auth: false,
    role: null,
  };
};

const PublicRoutes = () => {
  const auth = useAuth();

  return !auth ? <Navigate to="/login" /> : <Outlet />;
};

export default PublicRoutes;
