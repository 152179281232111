import { creditUrl } from '../utils';

export const getUsersList = ({ page, limit, ...params }) => {
  return {
    url: `${creditUrl}admin/admin-users`,
    method: 'GET',
    params: { page, limit, ...params },
  };
};

export const createUser = body => {
  return {
    url: `${creditUrl}admin/admin-users`,
    method: 'Post',
    body: body,
  };
};
