/* eslint-disable no-unused-vars */
/* eslint-disable func-style */
import { takeLatest, put, call } from 'redux-saga/effects';
import { execute } from 'api';

import {
  loadBundles,
  loadBundlesSuccess,
  loadBundlesFailed,
  createBundle,
  createSuccess,
  createFailed,
  updateBundle,
  updateSuccess,
  updateFailed,
  loadingBundleData,
  loadBundleDataSuccess,
  loadBundleDataFailed,
} from './slice';
import { showAlert } from 'redux/slices/uiSlice';

import {
  getBundlesRequest,
  getCreateBundleRequest,
  getUpdateBundleRequest,
  getBundleRequest,
} from 'api/requestCreators/credit';

function* handleLoadBundlesListRequests(action) {
  const filters = action?.payload;
  try {
    const {
      data: { data },
    } = yield call(execute, getBundlesRequest(filters));

    yield put(loadBundlesSuccess(data));
  } catch (error) {
    yield put(loadBundlesFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

function* handleCreateBundleRequests(action) {
  try {
    const item = {
      ...action.payload,
      premiumPercentage: +action.payload.premiumPercentage,
      paybackDuration: +action.payload.paybackDuration,
    };

    const {
      data: {
        data: { bundle },
      },
    } = yield call(execute, getCreateBundleRequest(item));

    yield put(createSuccess(bundle));
  } catch (error) {
    yield put(createFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}
function* handleUpdateBundleRequests(action) {
  try {
    const { id, ...item } = {
      ...action.payload,
      premiumPercentage: +action.payload.premiumPercentage,
      paybackDuration: +action.payload.paybackDuration,
    };
    const {
      data: { data },
    } = yield call(execute, getUpdateBundleRequest(item, id));
    yield put(updateSuccess(data));
  } catch (error) {
    yield put(updateFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

function* handleLoadBundleDataRequests(action) {
  const { payload: id } = action;
  try {
    const {
      data: { data: bundle },
    } = yield call(execute, getBundleRequest(id));
    yield put(loadBundleDataSuccess(bundle));
  } catch (error) {
    yield put(loadBundleDataFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

export function* watchBundlesListPageRequests() {
  yield takeLatest(loadBundles.type, handleLoadBundlesListRequests);
  yield takeLatest(createBundle.type, handleCreateBundleRequests);
  yield takeLatest(updateBundle.type, handleUpdateBundleRequests);
  yield takeLatest(loadingBundleData.type, handleLoadBundleDataRequests);
}
