import { all } from 'redux-saga/effects';
import { watchLoginPageRequests } from 'pages/login/store/saga';
import { watchBundlesListPageRequests } from 'pages/bundles/store/saga';
import { watchEntitiesListPageRequests } from 'pages/entities/store/saga';
import { watchRouteListRequests } from './slices/routeSlice/saga';
import { watchGovernmentListRequests } from './slices/government/saga';
import { watchFinancePageRequests } from 'pages/finance/store/saga';
import { watchCollectionsPageRequests } from 'pages/collection/store/saga';
import { watchCollectorsPageRequests } from 'pages/collectors/store/saga';
import { watchAllCollectionsPageRequest } from 'pages/allCollections/store/saga';
import { watchUsersPageRequests } from 'pages/users/store/saga';

export default function* rootSaga() {
  yield all([
    watchLoginPageRequests(),
    watchBundlesListPageRequests(),
    watchEntitiesListPageRequests(),
    watchRouteListRequests(),
    watchGovernmentListRequests(),
    watchCollectionsPageRequests(),
    watchFinancePageRequests(),
    watchCollectorsPageRequests(),
    watchAllCollectionsPageRequest(),
    watchUsersPageRequests(),
  ]);
}
