/* eslint-disable func-style */
import { takeLatest, put, call } from 'redux-saga/effects';
import { execute } from 'api';
import { downloadExcelSheet, handleUploadSheetError } from 'utils';
import {
  loadEntities,
  loadEntitiesSuccess,
  loadEntitiesFailed,
  createEntity,
  createEntitySuccess,
  createEntityFailed,
  loadingEntityData,
  loadEntityDataSuccess,
  loadEntityDataFailed,
  loadingEditEntity,
  loadingEditEntitySuccess,
  loadingEditEntityFailed,
  loadingApproveEntity,
  loadingApproveEntitySuccess,
  loadingApproveEntityFailed,
  loadingUploadEntities,
  loadingUploadEntitiesSuccess,
  loadingUploadEntitiesFailed,
  loadingDownloadEntities,
  loadingDownloadEntitiesSuccess,
  loadingDownloadEntitiesFailed,
} from './slice';
import { showAlert } from 'redux/slices/uiSlice';

import {
  getEntitiesRequest,
  getCreateEntityRequest,
  getEntityRequest,
  getApproveEntityRequest,
  getEditEntityRequest,
  getUploadEntitiesRequest,
  getDownloadTemplate,
} from 'api/requestCreators/credit';
import { showError } from 'redux/utility-sagas';

function* handleLoadEntitiesListRequests(action) {
  const filters = action?.payload;
  try {
    const {
      data: { data },
    } = yield call(execute, getEntitiesRequest(filters));

    yield put(loadEntitiesSuccess(data));
  } catch (error) {
    if (error.response?.data?.message?.includes('retailerId')) {
      yield put(
        showAlert({
          message: 'Invalid ID',
          type: 'error',
        })
      );
      return;
    }
    yield put(loadEntitiesFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

function* handleCreateEntityRequests(action) {
  try {
    const item = {
      ...action.payload,
    };

    const {
      data: { data },
    } = yield call(execute, getCreateEntityRequest(item));

    yield put(createEntitySuccess(data));
  } catch (error) {
    yield put(createEntityFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

function* handleLoadEntityDataRequests(action) {
  const { payload: id } = action;
  try {
    const {
      data: { data },
    } = yield call(execute, getEntityRequest(id));
    yield put(loadEntityDataSuccess(data));
  } catch (error) {
    yield put(loadEntityDataFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

function* handleLoadApproveEntityRequests(action) {
  const item = {
    ...action.payload,
  };
  try {
    const {
      data: { data },
    } = yield call(execute, getApproveEntityRequest(item));

    yield put(loadingApproveEntitySuccess(data));
  } catch (error) {
    yield put(loadingApproveEntityFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

function* handleEditEntityRequests(action) {
  const { values, id } = action.payload;
  try {
    const item = {
      ...values,
    };

    const {
      data: { data },
    } = yield call(execute, getEditEntityRequest(item, id));

    yield put(loadingEditEntitySuccess(data));
  } catch (error) {
    yield put(loadingEditEntityFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

function* handleUploadEntitiesRequests(action) {
  const entitiesData = { retailers: [...action.payload] };
  try {
    const {
      data: { data },
    } = yield call(execute, getUploadEntitiesRequest(entitiesData));
    yield put(loadingUploadEntitiesSuccess(data));
    yield put(loadEntities({ page: 1, limit: 10 }));
  } catch (error) {
    let handledResponse = handleUploadSheetError(error);
    if (error.response.status === 500) {
      yield put(
        showAlert({
          message: 'Error in bulk uploading, Please check the upload sheet',
          type: 'error',
        })
      );
    } else {
      downloadExcelSheet(handledResponse, 'uploadErrorSheet.xlsx');
      yield put(
        showAlert({
          message: 'Error in bulk uploading, Please check the downloaded error sheet',
          type: 'error',
        })
      );
      yield put(loadEntities({ page: 1, limit: 10 }));
    }
    yield put(loadingUploadEntitiesFailed({}));
  }
}

function* handleDownloadEntitiesRequests() {
  try {
    const response = yield call(execute, getDownloadTemplate());
    downloadExcelSheet(response, 'EntitiesTemplate.xlsx');
    yield put(loadingDownloadEntitiesSuccess());
  } catch (error) {
    yield put(loadingDownloadEntitiesFailed(error.response?.data));
    yield showError(error, 'Error in download, Please try again');
  }
}
export function* watchEntitiesListPageRequests() {
  yield takeLatest(loadEntities.type, handleLoadEntitiesListRequests);
  yield takeLatest(createEntity.type, handleCreateEntityRequests);
  yield takeLatest(loadingEntityData.type, handleLoadEntityDataRequests);
  yield takeLatest(loadingApproveEntity.type, handleLoadApproveEntityRequests);
  yield takeLatest(loadingEditEntity.type, handleEditEntityRequests);
  yield takeLatest(loadingUploadEntities.type, handleUploadEntitiesRequests);
  yield takeLatest(loadingDownloadEntities.type, handleDownloadEntitiesRequests);
}
