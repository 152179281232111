import { useState } from 'react';
import {
  HddOutlined,
  UsergroupAddOutlined,
  WalletOutlined,
  DollarOutlined,
  MoneyCollectOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';

const { Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem(
    <NavLink to="/">
      <span>Entities</span>
    </NavLink>,
    'entities',
    <UsergroupAddOutlined />
  ),
  getItem(
    <NavLink to="/bundles">
      <span>Bundles</span>
    </NavLink>,
    'bundles',
    <HddOutlined />
  ),
  getItem(
    <NavLink to="/finance?FinanceType=Owed">
      <span>Finance</span>
    </NavLink>,
    'finance',
    <WalletOutlined />
  ),
  getItem(
    <NavLink to="/collectors">
      <span>Collectors</span>
    </NavLink>,
    'collectors',
    <MoneyCollectOutlined />
  ),
  getItem(
    <NavLink to="/all-collections">
      <span>All Collections</span>
    </NavLink>,
    'all-collections',
    <DollarOutlined />
  ),
  getItem(
    <NavLink to="/users">
      <span>Users</span>
    </NavLink>,
    'users',
    <UserOutlined />
  ),
];
const BaseSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter(i => i);

  return (
    <Sider
      width={200}
      className="site-layout-background"
      collapsible
      collapsed={collapsed}
      trigger={null}
      onCollapse={value => setCollapsed(value)}
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={[pathSnippets[0] ?? 'entities']}
        selectedKeys={[pathSnippets[0] ?? 'entities']}
        defaultOpenKeys={[pathSnippets[0] ?? 'entities']}
        style={{
          height: '100%',
          borderRight: 0,
          paddingTop: '10px',
        }}
        items={items}
      />
    </Sider>
  );
};

export default BaseSidebar;
