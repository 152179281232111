import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    isLoading: false,
    usersList: [],
    totalCount: 10,
    error: null,
    createStatus: 'idle',
  },
  reducers: {
    fetchUsersList: state => {
      state.isLoading = true;
    },
    fetchUsersListSuccess: (state, action) => {
      state.isLoading = false;
      state.usersList = action.payload?.admins;
      state.totalCount = action.payload?.totalCount;
    },

    fetchUsersListFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    createUser: state => state,
    createUserSuccess: state => {
      state.createStatus = 'success';
      state.error = null;
    },
    createUserFailed: (state, action) => {
      state.error = action.payload;
      state.createStatus = 'error';
    },

    restCreateStatus: state => {
      state.error = null;
      state.createStatus = 'idle';
    },
  },
});

export const {
  fetchUsersList,
  fetchUsersListSuccess,
  fetchUsersListFailed,

  createUser,
  createUserSuccess,
  createUserFailed,

  restCreateStatus,
} = usersSlice.actions;
export default usersSlice.reducer;
