import { Navigate, Outlet } from 'react-router-dom';
import { setAuthToken } from 'api';
const useAuth = () => {
  let user;

  const getUser = localStorage.getItem('user');

  if (getUser) {
    user = JSON.parse(getUser);
  }

  if (user) {
    return {
      auth: true,
      role: user?.role,
    };
  }
  return {
    auth: false,
    role: null,
  };
};

const ProtectedRoutes = () => {
  const { auth } = useAuth();
  (async function () {
    const getToken = localStorage.getItem('token');
    setAuthToken(getToken);
  })();
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
