import axios from 'axios';

export const setAuthToken = authToken => {
  axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
};

export const execute = ({ method, url, body, params, headers, responseType = 'json' }) => {
  return axios
    .request({
      url,
      method,
      data: body,
      params,
      headers,
      responseType,
    })
    .catch(error => {
      throw error;
    });
};
