import { takeLatest, put, call } from 'redux-saga/effects';
import { execute } from 'api';

import { loadAllCollections, loadAllCollectionsSuccess, loadAllCollectionsFailed } from './slice';
import { showAlert } from 'redux/slices/uiSlice';

import { getAllCollectionsRequest } from 'api/requestCreators/credit';

function* handelLoadAllCollectionsRequest(action) {
  const params = {
    ...action?.payload,
  };
  try {
    const {
      data: { data },
    } = yield call(execute, getAllCollectionsRequest(params));
    yield put(loadAllCollectionsSuccess(data));
  } catch (error) {
    yield put(loadAllCollectionsFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

export function* watchAllCollectionsPageRequest() {
  yield takeLatest(loadAllCollections.type, handelLoadAllCollectionsRequest);
}
