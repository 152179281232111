import { Layout } from 'antd';
import BaseSidebar from './sidebar';
import BaseHeader from './header';
import { Outlet } from 'react-router-dom';

const BaseLayOut = () => {
  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <BaseHeader />
      <Layout>
        <BaseSidebar />
        <Layout>
          <Outlet />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default BaseLayOut;
