import React, { Component } from 'react';
import errorImage from './images/micro-app-error-image.png';
import PropTypes from 'prop-types';
import { styles } from './styles';
class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError = error => {
    return { hasError: !!error };
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? (
      <section style={styles.container}>
        <img src={errorImage} alt="person fix issue" style={styles.image} />
        <p style={styles.message}>Opps! Looks like something went wrong..</p>
      </section>
    ) : (
      children
    );
  }
}

export { ErrorBoundary };

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};
