/* eslint-disable func-style */
import { takeLatest, put, call } from 'redux-saga/effects';
import { execute } from 'api';

import { loadGovernment, loadGovernmentSuccess, loadGovernmentFailed } from './slice';
import { getGovernmentRequest } from 'api/requestCreators/government';
import { showAlert } from 'redux/slices/uiSlice';

function* handleLoadGovernmentListRequests() {
  try {
    const {
      data: { data },
    } = yield call(execute, getGovernmentRequest());

    yield put(loadGovernmentSuccess(data));
  } catch (error) {
    yield put(loadGovernmentFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

export function* watchGovernmentListRequests() {
  yield takeLatest(loadGovernment.type, handleLoadGovernmentListRequests);
}
