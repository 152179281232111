import { creditUrl } from '../utils';

export const getOwedList = params => {
  return {
    url: `${creditUrl}admin/settlement`,
    method: 'GET',
    params: { ...params },
  };
};

export const getSettledList = params => {
  return {
    url: `${creditUrl}admin/settlement/history`,
    method: 'GET',
    params: { ...params },
  };
};

export const settleAmount = body => {
  return {
    url: `${creditUrl}admin/settlement`,
    method: 'Post',
    body: body,
  };
};

export const getSettledDetailsRequest = ({ page, limit, id }) => {
  return {
    url: `${creditUrl}admin/settlement/history/${id}?page=${page}&limit=${limit}`,
    method: 'GET',
  };
};
