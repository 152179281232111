import UIReducer from './slices/uiSlice';
import appReducer from './slices/appSlice';
import loginReducer from 'pages/login/store/slice';
import bundlesReducer from 'pages/bundles/store/slice';
import entitiesReducer from 'pages/entities/store/slice';
import routesReducer from './slices/routeSlice/slice';
import governmentReducer from './slices/government/slice';
import collectionsReducer from 'pages/collection/store/slice';
import financeReducer from 'pages/finance/store/slice';
import collectorsReducer from 'pages/collectors/store/slice';
import allCollectionsReducer from 'pages/allCollections/store/slice';
import usersReducer from 'pages/users/store/slice';

const createRootReducer = () => {
  return {
    UIReducer,
    appReducer,
    loginReducer,
    bundlesReducer,
    entitiesReducer,
    routesReducer,
    governmentReducer,
    collectionsReducer,
    financeReducer,
    collectorsReducer,
    allCollectionsReducer,
    usersReducer,
  };
};

export default createRootReducer;
