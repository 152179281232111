import { createSlice } from '@reduxjs/toolkit';

const financeSlice = createSlice({
  name: 'finance',
  initialState: {
    isLoading: false,
    owedList: [],
    owedListTotalCount: 10,
    providerId: null,
    settledList: [],
    settledListTotalCount: 10,
    totalOwedAmount: 0,
    totalSettledAmount: 0,
    settleDialogVisible: false,
    settleAmountStatus: 'idle',
    error: null,
    settledDetailsList: null,
    settledDetailsTotalCount: 10,
  },
  reducers: {
    fetchOwedList: state => {
      state.isLoading = true;
    },
    fetchOwedListSuccess: (state, action) => {
      const {
        response: { retailersData, id },
        totalOwedAmount,
        totalCount,
      } = action.payload;
      state.isLoading = false;
      state.owedList = retailersData;
      state.totalOwedAmount = totalOwedAmount;
      state.owedListTotalCount = totalCount ?? state.owedListTotalCount;
      state.providerId = id;
    },

    fetchOwedListFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchSettledList: state => {
      state.isLoading = true;
    },
    fetchSettledListSuccess: (state, action) => {
      const { settlementsHistory, totalAmount, totalCount } = action.payload;
      state.isLoading = false;
      state.settledList = settlementsHistory;
      state.totalSettledAmount = totalAmount ?? 0;
      state.settledListTotalCount = totalCount;
    },
    fetchSettledListFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    settleAmount: state => {
      state.settleAmountStatus = 'loading';
    },
    settleAmountSuccess: (state, action) => {
      const { amount } = action.payload;
      state.settleAmountStatus = 'succuss';

      state.settleDialogVisible = false;
      if (amount) {
        state.totalOwedAmount -= amount;
        state.totalSettledAmount += amount;
      }
    },
    settleAmountFailed: (state, action) => {
      state.error = action.payload;
      state.settleAmountStatus = 'failure';
    },
    changeTheDialogVisibility: (state, action) => {
      state.settleDialogVisible = action.payload;
    },
    resetStatus: state => {
      state.error = null;
      state.settleAmountStatus = 'idle';
    },
    loadSettledDetails: state => {
      state.isLoading = true;
      return state;
    },
    loadSettledDetailsSuccess: (state, action) => {
      state.isLoading = false;
      state.settledDetailsList = action.payload?.purchaseTransactionsAndRetailerData;
      state.settledDetailsTotalCount = action.payload?.totalCount;
    },
    loadSettledDetailsFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  fetchOwedList,
  fetchOwedListSuccess,
  fetchOwedListFailed,

  fetchSettledList,
  fetchSettledListSuccess,
  fetchSettledListFailed,

  settleAmount,
  settleAmountSuccess,
  settleAmountFailed,

  changeTheDialogVisibility,
  resetStatus,

  loadSettledDetails,
  loadSettledDetailsSuccess,
  loadSettledDetailsFailed,
} = financeSlice.actions;
export default financeSlice.reducer;
