import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  createStatus: 'idle',
  error: null,
  data: null,
  actionStatus: 'idle',
  totalCount: 0,
  defaultPage: 1,
};

const allCollectionsListSlice = createSlice({
  name: 'allCollectionsList',
  initialState,
  reducers: {
    loadAllCollections: state => state,
    loadAllCollectionsSuccess: (state, action) => {
      state.data = action?.payload.collectors;
      state.totalCount = action?.payload?.totalCount;
      state.defaultPage = action?.payload?.previous + 1;
      state.isLoading = false;
    },
    loadAllCollectionsFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    restCreateStatus: state => {
      state.createStatus = 'idle';
    },
    resetState: () => {
      return { ...initialState };
    },
  },
});

const { actions, reducer } = allCollectionsListSlice;

export const { loadAllCollections, loadAllCollectionsSuccess, loadAllCollectionsFailed, restCreateStatus } = actions;

export default reducer;
