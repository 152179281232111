import { takeLatest, put, call } from 'redux-saga/effects';
import { execute } from 'api';

import {
  fetchUsersList,
  fetchUsersListFailed,
  fetchUsersListSuccess,
  createUserFailed,
  createUserSuccess,
  createUser as createUserAction,
} from './slice';
import { showAlert } from 'redux/slices/uiSlice';

import { getUsersList, createUser } from 'api/requestCreators/users';

function* handleLoadUsersListRequests(action) {
  const filters = action?.payload;
  try {
    const {
      data: { data },
    } = yield call(execute, getUsersList(filters));

    yield put(fetchUsersListSuccess(data));
  } catch (error) {
    yield put(fetchUsersListFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

function* handleCreateUserRequest(action) {
  const userObject = action?.payload;
  try {
    const {
      data: { data },
    } = yield call(execute, createUser(userObject));

    yield put(createUserSuccess(data));
  } catch (error) {
    yield put(createUserFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

export function* watchUsersPageRequests() {
  yield takeLatest(fetchUsersList.type, handleLoadUsersListRequests);
  yield takeLatest(createUserAction.type, handleCreateUserRequest);
}
