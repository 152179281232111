import { takeLatest, put, call } from 'redux-saga/effects';
import { execute } from 'api';

import {
  fetchOwedList,
  fetchOwedListFailed,
  fetchOwedListSuccess,
  fetchSettledList,
  fetchSettledListFailed,
  fetchSettledListSuccess,
  settleAmount,
  settleAmountSuccess,
  settleAmountFailed,
  loadSettledDetails,
  loadSettledDetailsSuccess,
  loadSettledDetailsFailed,
} from './slice';
import { showAlert } from 'redux/slices/uiSlice';

import {
  getOwedList,
  getSettledList,
  settleAmount as settleRetailerAmount,
  getSettledDetailsRequest,
} from 'api/requestCreators/finance';
function* handleLoadOwedListRequests(action) {
  const filters = action?.payload;

  try {
    const {
      data: { data },
    } = yield call(execute, getOwedList(filters));

    yield put(fetchOwedListSuccess(data));
  } catch (error) {
    yield put(fetchOwedListFailed(error.response?.data));
    yield put(
      showAlert({
        message: error.response?.data?.message,
        type: 'error',
      })
    );
  }
}

function* handleLoadSettledListRequests(action) {
  const filters = action?.payload;

  try {
    const {
      data: { data },
    } = yield call(execute, getSettledList(filters));

    yield put(fetchSettledListSuccess(data));
  } catch (error) {
    yield put(fetchSettledListFailed(error.response?.data));
    yield put(
      showAlert({
        message: error.response?.data?.message,
        type: 'error',
      })
    );
  }
}

function* handleSettleAmountRequests(action) {
  const settleObject = action?.payload;
  try {
    const {
      data: { data },
    } = yield call(execute, settleRetailerAmount(settleObject));

    yield put(settleAmountSuccess(data));
    yield put(fetchOwedList({ page: 1, limit: 10 }));
  } catch (error) {
    yield put(settleAmountFailed(error.response?.data));
    yield put(
      showAlert({
        message: error.response?.data?.message,
        type: 'error',
      })
    );
  }
}

function* handleLoadSettledDetailsRequests(action) {
  const filters = { ...action?.payload };
  try {
    const {
      data: { data },
    } = yield call(execute, getSettledDetailsRequest(filters));
    yield put(loadSettledDetailsSuccess(data));
  } catch (error) {
    yield put(loadSettledDetailsFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

export function* watchFinancePageRequests() {
  yield takeLatest(fetchOwedList.type, handleLoadOwedListRequests);
  yield takeLatest(fetchSettledList.type, handleLoadSettledListRequests);
  yield takeLatest(settleAmount.type, handleSettleAmountRequests);
  yield takeLatest(loadSettledDetails.type, handleLoadSettledDetailsRequests);
}
