import { creditUrl } from '../utils';
import qs from 'qs';

export const getEntitiesRequest = ({ page, limit, filter }) => {
  return {
    url: `${creditUrl}admin/retailers/?page=${page}&limit=${limit}`,
    method: 'GET',
    params: { filter },
    paramsSerializer: function (params) {
      return qs.stringify(params, {
        skipNulls: true,
      });
    },
  };
};

export const getEntityRequest = id => {
  return {
    url: `${creditUrl}admin/retailers/${id}`,
    method: 'GET',
  };
};

export const getCreateEntityRequest = data => {
  return {
    url: `${creditUrl}admin/retailers`,
    method: 'POST',
    body: data,
  };
};

export const getApproveEntityRequest = data => {
  return {
    url: `${creditUrl}admin/retailers/approve`,
    method: 'PUT',
    body: data,
  };
};

export const getEditEntityRequest = (data, id) => {
  return {
    url: `${creditUrl}admin/retailers/${id}`,
    method: 'PUT',
    body: data,
  };
};

export const getUploadEntitiesRequest = data => {
  return {
    url: `${creditUrl}admin/retailers/bulk`,
    method: 'POST',
    body: data,
    responseType: 'arraybuffer',
  };
};

export const getDownloadTemplate = () => {
  return {
    url: `${creditUrl}admin/retailers/template`,
    method: 'GET',
    responseType: 'arraybuffer',
  };
};

export const getBundlesRequest = ({ page, limit }) => {
  return {
    url: `${creditUrl}admin/bundles/?page=${page}&limit=${limit}`,
    method: 'GET',
  };
};

export const getCreateBundleRequest = data => {
  return {
    url: `${creditUrl}admin/bundles`,
    method: 'POST',
    body: data,
  };
};

export const getUpdateBundleRequest = (data, id) => {
  return {
    url: `${creditUrl}admin/bundles/${id}`,
    method: 'PUT',
    body: data,
  };
};

export const getBundleRequest = id => {
  return {
    url: `${creditUrl}admin/bundles/${id}`,
    method: 'GET',
  };
};

export const getAllCollectionsRequest = ({ page, limit }) => {
  return {
    url: `${creditUrl}admin/collectors/settlements/?page=${page}&limit=${limit}`,
    method: 'GET',
  };
};
