import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  createStatus: 'idle',
  error: null,
  data: null,
};

const governmentSlice = createSlice({
  name: 'governmentList',
  initialState,
  reducers: {
    loadGovernment: state => state,
    loadGovernmentSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    loadGovernmentFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetState: () => {
      return { ...initialState };
    },
  },
});

const { actions, reducer } = governmentSlice;

export const { loadGovernment, loadGovernmentSuccess, loadGovernmentFailed } = actions;

export default reducer;
