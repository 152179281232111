// eslint-disable-next-line no-unused-vars
import { takeLatest, put, call } from 'redux-saga/effects';
// eslint-disable-next-line no-unused-vars
import { execute } from 'api';
import {
  loadCurrentCollections,
  loadCurrentCollectionsSuccess,
  loadCurrentCollectionsFailed,
  loadHistoryCollections,
  loadHistoryCollectionsSuccess,
  loadHistoryCollectionsFailed,
  createSettlement,
  createSettlementSuccess,
  createSettlementFailed,
} from './slice';
import { showAlert } from 'redux/slices/uiSlice';

import {
  getCurrentCollectionsRequest,
  getHistoryCollectionsRequest,
  postRetailerSettlementRequest,
} from 'api/requestCreators/collection';

function* handleLoadCurrentCollectionListRequests(action) {
  const filters = action?.payload;
  const params = {
    ...filters,
  };
  try {
    const response = yield call(execute, getCurrentCollectionsRequest(params));
    let dataToReducer = {
      data: response?.data?.data?.dueAmounts,
      totalCount: response?.data?.data?.totalCount,
    };

    yield put(loadCurrentCollectionsSuccess(dataToReducer));
  } catch (error) {
    yield put(loadCurrentCollectionsFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

function* handleLoadHistoryCollectionListRequests(action) {
  const filters = action?.payload;
  const params = {
    ...filters,
  };
  try {
    const response = yield call(execute, getHistoryCollectionsRequest(params));
    let dataToReducer = {
      totalCount: response?.data?.data?.totalCount,
    };

    const formattedData = response?.data?.data?.collections.map(item => {
      return {
        ...item,
        retailer_id: item.retailer?.id,
        store_name: item.retailer?.store?.name,
        store_id: item.retailer?.store?.id,
      };
    });
    dataToReducer.data = formattedData;
    yield put(loadHistoryCollectionsSuccess(dataToReducer));
  } catch (error) {
    yield put(loadHistoryCollectionsFailed(error.response?.data));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}

function* handleSettleCollectionRequest(action) {
  const body = action?.payload;
  try {
    const response = yield call(execute, postRetailerSettlementRequest({ body }));
    let dataToReducer = {
      data: response.data,
    };

    yield put(createSettlementSuccess(dataToReducer));
  } catch (error) {
    let errorMessage = 'Error occured';
    if (error.response?.data?.message) errorMessage = error.response?.data?.message;
    yield put(createSettlementFailed(errorMessage));
  }
}

export function* watchCollectionsPageRequests() {
  yield takeLatest(loadCurrentCollections.type, handleLoadCurrentCollectionListRequests);
  yield takeLatest(loadHistoryCollections.type, handleLoadHistoryCollectionListRequests);
  yield takeLatest(createSettlement.type, handleSettleCollectionRequest);
}
