import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'app',
  initialState: {
    user: null,
  },
  reducers: {
    setUserData: (state, action) => {
      state.user = action?.payload;
      return state;
    },
  },
});

const { actions, reducer } = slice;

export const { setUserData } = actions;

export default reducer;
