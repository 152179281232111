import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  createStatus: 'idle',
  error: null,
  data: null,
  token: '',
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loadLogin: state => state,
    setLoginData: (state, action) => {
      state.data = action.payload;
      state.isLoading = true;
    },
    loginSuccess: (state, action) => {
      state.token = action.payload;
      state.isLoading = false;
    },
    loginFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetState: () => {
      return { ...initialState };
    },
  },
});

const { actions, reducer } = loginSlice;

export const { loadLogin, loginSuccess, loginFailed, setLoginData } = actions;

export default reducer;
