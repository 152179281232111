import { creditUrl } from '../utils';
import qs from 'qs';

export const getRoutesRequest = ({ areaId, ...filters }) => {
  return {
    url: `${creditUrl}routes`,
    method: 'GET',
    params: {
      filter: { areaId, ...filters },
    },
    paramsSerializer: function (params) {
      return qs.stringify(params, {
        skipNulls: true,
      });
    },
  };
};
