import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { Spin } from 'antd';

const EntitiesPage = lazy(() => import('pages/entities'));
const EntitiesDetails = lazy(() => import('pages/entities/details'));
const BundlesPage = lazy(() => import('pages/bundles'));
const BundleDetails = lazy(() => import('pages/bundles/details'));
const FinancePage = lazy(() => import('pages/finance'));
const CollectorsPage = lazy(() => import('pages/collectors'));
const CollectorsDetailsPage = lazy(() => import('pages/collectors/details'));
const ActionsCollectorPage = lazy(() => import('pages/collectors/actions-collector'));
const HistoryDetails = lazy(() => import('pages/collectors/history-details'));
const AllCollectionsPage = lazy(() => import('pages/allCollections'));
const UsersPage = lazy(() => import('pages/users'));
const UsersDetails = lazy(() => import('pages/users/details'));

const customRoutes = [
  {
    path: 'entities',
    component: <Outlet />,
    roles: [],
    pathName: 'entities',
    parent: true,
    children: [
      {
        index: true,
        path: 'entities',
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <EntitiesPage />
          </Suspense>
        ),
        roles: [],
        pathName: 'entities list',
      },
      {
        path: 'entities/add-entity',
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <EntitiesDetails />
          </Suspense>
        ),
        pathName: 'add-entity',
        roles: [],
      },
      {
        path: 'entities/entity-details/:id',
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <EntitiesDetails />
          </Suspense>
        ),
        pathName: 'entity-details',
        roles: [],
      },
      {
        path: 'entities/entity-edit/:id',
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <EntitiesDetails />
          </Suspense>
        ),
        pathName: 'entity-edit',
        roles: [],
      },
    ],
  },
  {
    path: 'bundles',
    component: <Outlet />,
    roles: [],
    pathName: 'bundles',
    parent: true,
    children: [
      {
        index: true,
        path: 'bundles',
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <BundlesPage />
          </Suspense>
        ),
        roles: [],
        pathName: 'bundles list',
      },
      {
        path: 'bundles/add-bundle',
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <BundleDetails />
          </Suspense>
        ),
        pathName: 'add-bundle',
        roles: [],
      },
      {
        path: 'bundles/bundle-details/:id',
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <BundleDetails />
          </Suspense>
        ),
        pathName: 'bundle-details',
        roles: [],
      },
      {
        path: 'bundles/bundle-edit/:id',
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <BundleDetails />
          </Suspense>
        ),
        pathName: 'bundle-edit',
        roles: [],
      },
    ],
  },
  {
    path: 'finance',
    component: <Outlet />,
    roles: [],
    pathName: 'finance',
    parent: true,
    children: [
      {
        path: 'finance',
        index: true,
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <FinancePage />
          </Suspense>
        ),
        pathName: 'finance',
        roles: [],
      },
    ],
  },
  {
    path: 'collectors',
    component: <Outlet />,
    roles: [],
    pathName: 'collectors',
    parent: true,
    children: [
      {
        path: 'collectors',
        index: true,
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <CollectorsPage />
          </Suspense>
        ),
        pathName: 'collectors',
        roles: [],
      },
      {
        path: 'collectors/collector-details/:id',
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <CollectorsDetailsPage />
          </Suspense>
        ),
        pathName: 'collector-details',
        roles: [],
        children: [
          {
            index: true,
            path: 'collectors/collector-details/:id/history-details/:id',
            component: (
              <Suspense fallback={<Spin tip="Loading..." />}>
                <HistoryDetails />
              </Suspense>
            ),
            pathName: 'history-details',
            roles: [],
          },
        ],
      },
      {
        path: 'collectors/add-collector',
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <ActionsCollectorPage />
          </Suspense>
        ),
        pathName: 'add-collector',
        roles: [],
      },
      {
        path: 'collectors/edit-collector/:id',
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <ActionsCollectorPage />
          </Suspense>
        ),
        pathName: 'edit-collector',
        roles: [],
      },
    ],
  },
  {
    path: 'all-collections',
    component: <Outlet />,
    roles: [],
    pathName: 'allCollections',
    parent: true,
    children: [
      {
        path: 'all-collections',
        index: true,
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <AllCollectionsPage />
          </Suspense>
        ),
        pathName: 'allCollections',
        roles: [],
      },
    ],
  },
  {
    path: 'users',
    component: <Outlet />,
    roles: [],
    pathName: 'users',
    parent: true,
    children: [
      {
        path: 'users',
        index: true,
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <UsersPage />
          </Suspense>
        ),
        pathName: 'users',
        roles: [],
      },
      {
        path: 'users/add-user',
        index: true,
        component: (
          <Suspense fallback={<Spin tip="Loading..." />}>
            <UsersDetails mode="add" />
          </Suspense>
        ),
        pathName: 'add-user',
        roles: [],
      },
    ],
  },
];

export { customRoutes };
