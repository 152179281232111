/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pageLoading: false,
  isLoading: true,
  currentCollectionsData: null,
  historyCollectionsData: null,
  currentCollectionsTotalCount: 10,
  historyCollectionsTotalCount: 10,

  settleRequestLoading: false,
  createStatus: 'idle',
  error: null,
  errorMessage: null,
};

const collectionsSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    loadCurrentCollections: (state, action) => {
      state.isLoading = true;
      if (!state.currentCollectionsData) state.pageLoading = true;
      state.createStatus = 'idle';
      return state;
    },
    loadCurrentCollectionsSuccess: (state, action) => {
      state.currentCollectionsData = action.payload.data.map((item, index) => {
        return {
          ...item,
          key: +index,
        };
      });
      state.currentCollectionsTotalCount = action.payload.totalCount;
      state.isLoading = false;
      state.pageLoading = false;
      state.createStatus = 'idle';
    },
    loadCurrentCollectionsFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.pageLoading = false;
    },

    loadHistoryCollections: (state, action) => {
      state.isLoading = true;
      if (!state.historyCollectionsData) state.pageLoading = true;
      return state;
    },
    loadHistoryCollectionsSuccess: (state, action) => {
      state.historyCollectionsData = action.payload.data.map((item, index) => {
        return {
          ...item,
          key: +index,
        };
      });
      state.historyCollectionsTotalCount = action.payload.totalCount;
      state.isLoading = false;
      state.pageLoading = false;
    },
    loadHistoryCollectionsFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.pageLoading = false;
    },

    createSettlement: (state, action) => {
      state.createStatus = 'idle';
      state.settleRequestLoading = true;
      state.errorMessage = null;
      return state;
    },
    createSettlementSuccess: (state, action) => {
      state.createStatus = 'success';
      state.settleRequestLoading = false;
      state.error = null;
    },
    createSettlementFailed: (state, action) => {
      state.createStatus = 'error';
      state.errorMessage = action.payload;
      state.settleRequestLoading = false;
    },

    resetState: () => {
      return { ...initialState };
    },
  },
});

const { actions, reducer } = collectionsSlice;

export const {
  loadCurrentCollections,
  loadCurrentCollectionsSuccess,
  loadCurrentCollectionsFailed,

  loadHistoryCollections,
  loadHistoryCollectionsSuccess,
  loadHistoryCollectionsFailed,

  createSettlement,
  createSettlementSuccess,
  createSettlementFailed,

  resetState,
} = actions;

export default reducer;
