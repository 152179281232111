/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  createStatus: 'idle',
  error: null,
  data: null,
  actionStatus: 'idle',
  bundleItem: null,
  totalCount: 10,
};

const bundlesListSlice = createSlice({
  name: 'bundlesList',
  initialState,
  reducers: {
    loadBundles: (state, action) => state,
    loadBundlesSuccess: (state, action) => {
      state.data = action?.payload?.bundles;
      state.totalCount = action?.payload?.totalCount;
      state.isLoading = false;
    },
    loadBundlesFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    createBundle: (state, action) => state,
    createSuccess: (state, action) => {
      state.createStatus = 'success';
      state.isLoading = false;
      state.error = null;
    },
    createFailed: (state, action) => {
      state.createStatus = 'error';
      state.error = action.payload;
      state.isLoading = false;
    },
    updateBundle: (state, action) => state,
    updateSuccess: (state, action) => {
      state.createStatus = 'success';
      state.isLoading = false;
      state.error = null;
    },
    updateFailed: (state, action) => {
      state.createStatus = 'error';
      state.error = action.payload;
      state.isLoading = false;
    },
    loadingBundleData: state => state,
    loadBundleDataSuccess: (state, action) => {
      state.bundleItem = action.payload;
      state.isLoading = false;
    },
    setItemBundle: (state, action) => {
      state.bundleItem = action.payload;
      state.isLoading = false;
    },
    loadBundleDataFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    resetBundlesState: state => {
      state.bundleItem = initialState.bundleItem;
      state.error = null;
    },
    restCreateStatus: state => {
      state.createStatus = 'idle';
    },
    resetState: () => {
      return { ...initialState };
    },
  },
});

const { actions, reducer } = bundlesListSlice;

export const {
  loadBundles,
  loadBundlesSuccess,
  loadBundlesFailed,

  createBundle,
  createSuccess,
  createFailed,

  updateBundle,
  updateSuccess,
  updateFailed,

  loadingBundleData,
  loadBundleDataSuccess,
  loadBundleDataFailed,

  setItemBundle,
  resetBundlesState,
  restCreateStatus,
} = actions;

export default reducer;
